import React, { MouseEvent, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { IoMdLogIn } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../auth';
import ProfileDropdown from '../../profile-dropdown';
import ProfilePicture from '../../profile-picture';
import Tooltip from '../../utils/tooltip';
import DisclaimerModal from '../../video-chat/disclaimer-modal';
import './header.scss';
import Logo from './logo';

export default function Header() {
  const { isAuthenticated, loginWithRedirect } = useAuth();
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  function handleLogin() {
    loginWithRedirect();
  }

  function handleCancelDisclaimer() {
    setShowDisclaimer(false);
  }

  function handleConfirmDisclaimer() {
    setShowDisclaimer(false);
    handleLogin();
  }

  function handleLoginButtonClick(event: MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    setShowDisclaimer(true);
  }
  const UseableLogo = () => {
    if( process.env.REACT_APP_LOGO ){
      return <img style={ {maxHeight : '2em'}} className="header-home-icon" src={process.env.REACT_APP_LOGO} alt="Logo" ></img>
    }
    return <Logo className="header-home-icon" />;
  }

  return (
    <header className="app-header">
      <Navbar
        className="navbar-dark header-container justify-content-between"
        expand="lg">
        <Tooltip id="home" title="Home" placement="bottom">
          <NavLink className="text-white nav-link" to="/">
            <UseableLogo></UseableLogo>
          </NavLink>
        </Tooltip>

        {!isAuthenticated && (
          <Tooltip id="login" title="Login" placement="bottom">
            <div
              className="login-button-container"
              onClick={handleLoginButtonClick}>
              <p>Examiner</p>
              <a className="text-white nav-link">
                <IoMdLogIn className="login-icon" size="2em" />
              </a>
            </div>
          </Tooltip>
        )}
        {isAuthenticated && (
          <div className="text-white nav-link">
            <ProfileDropdown>
              <ProfilePicture className="header-profile-picture" />
            </ProfileDropdown>
          </div>
        )}
        <DisclaimerModal
          show={showDisclaimer}
          onCancel={handleCancelDisclaimer}
          onConfirm={handleConfirmDisclaimer}
        />
      </Navbar>
    </header>
  );
}
