import {
  LocalAudioTrack,
  LocalAudioTrackPublication,
  LocalVideoTrack,
  LocalVideoTrackPublication,
} from 'twilio-video';

export type VideoFacingMode = 'user' | 'environment';

export type MediaDevices = {
  audio: MediaDeviceInfo[];
  video: MediaDeviceInfo[];
};

/**
 * Used to request a token for joining an existing Video Chat
 */
export type GetVideoChatTokenDTO = {
  appointment_code: string;
};

/**
 * Used a response from the server to give the client access to a Video Chat
 *
 * A null token value means that some kind of error was encountered
 */
export type VideoChatTokenDTO = {
  token: string | null;
  error?: string;
};

export type TrackPublicationType =
  | LocalAudioTrackPublication
  | LocalVideoTrackPublication;

export type AudioTrackType = LocalAudioTrack;
export type VideoTrackType = LocalVideoTrack;

export type TrackType = AudioTrackType | VideoTrackType;

export enum NetworkQualityLevel {
  NETWORK_BROKEN_RECONNECTING,
  VERY_BAD_NETWORK,
  BAD_NETWORK,
  AVERAGE_NETWORK,
  GOOD_NETWORK,
  VERY_GOOD_NETWORK,
}

export enum NetworkQualityVerbosity {
  NONE,
  MINIMAL,
  MODERATE,
  DETAILED,
}
