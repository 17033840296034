import { createLocalTracks, LocalDataTrack, LocalTrack } from 'twilio-video';
import { errorLog, debugLog } from '../../../utils';
import {
  AudioTrackType,
  MediaDevices,
  TrackType,
  VideoFacingMode,
  VideoTrackType,
} from '../types';

const AUDIO_ENABLED = 'audio-enabled';
const VIDEO_ENABLED = 'video-enabled';
const PREFERRED_VIDEO = 'preferred-video';
const USER = 'user';
const ENVIRONMENT = 'environment';

/**
 * Checks to see if a device has media enabled
 */
export function mediaEnabled() {
  return navigator && 'mediaDevices' in navigator;
}

/**
 * Finds all local audio and video devices
 */
export async function getMediaDevices(): Promise<MediaDevices> {
  const devices: MediaDevices = {
    audio: [],
    video: [],
  };

  try {
    const allDevices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
    for (const device of allDevices) {
      if (device.deviceId === 'default') {
        continue;
      } else if (device.kind === 'audioinput') {
        devices.audio.push(device);
      } else if (device.kind === 'videoinput') {
        devices.video.push(device);
      }
    }
  } catch (err) {
    debugLog('encountered an error while using getMediaDevices');
    errorLog(err);
  }

  return devices;
}

export async function initialMediaTracks(): Promise<LocalTrack[]> {
  const videoPreferrence = getPreferredVideo();
  let tracks: LocalTrack[] = [];

  if (isFacingMode(videoPreferrence)) {
    tracks = await createLocalTracks({
      audio: true,
      video: { facingMode: videoPreferrence },
    });
  } else {
    const { video } = await getMediaDevices();
    const device = video.find((device) => device.label === videoPreferrence);

    // TODO: if using video device ID, maybe try to find if there is an associated audio device
    // if an associated audio device is found, use its id for the audio track
    tracks = await createLocalTracks({
      audio: true,
      video: device ? { deviceId: device.deviceId } : { facingMode: USER },
    });
  }

  if (Array.isArray(tracks)) {
    const audioEnabled = getAudioEnabled();
    const videoEnabled = getVideoEnabled();
    for (const track of tracks) {
      if (isVideoTrack(track)) {
        debugLog('using video device:', track.mediaStreamTrack.label);
        setPreferredVideo(track.mediaStreamTrack.label);
        if (!videoEnabled) {
          track.disable();
        }
      } else if (isAudioTrack(track)) {
        debugLog('using audio device:', track.mediaStreamTrack.label);
        if (!audioEnabled) {
          track.disable();
        }
      }
    }
  }

  return tracks;
}

export function isAudioTrack(
  track: TrackType | LocalDataTrack | null,
): track is AudioTrackType {
  return track !== null && track.kind === 'audio';
}

export function isVideoTrack(
  track: TrackType | LocalDataTrack | null,
): track is VideoTrackType {
  return track !== null && track.kind === 'video';
}

/* ~~~ User Preference Utils ~~~ */

export function getAudioEnabled() {
  const value = window.localStorage.getItem(AUDIO_ENABLED);
  if (value === 'false') {
    return false;
  }
  return true;
}

export function setAudioEnabled(value: boolean) {
  if (value) {
    window.localStorage.setItem(AUDIO_ENABLED, 'true');
  } else {
    window.localStorage.setItem(AUDIO_ENABLED, 'false');
  }
}

export function getVideoEnabled() {
  const value = window.localStorage.getItem(VIDEO_ENABLED) || 'true';
  if (value === 'false') {
    return false;
  }
  return true;
}

export function setVideoEnabled(value: boolean) {
  if (value) {
    window.localStorage.setItem(VIDEO_ENABLED, 'true');
  } else {
    window.localStorage.setItem(VIDEO_ENABLED, 'false');
  }
}

export function getPreferredVideo() {
  return window.localStorage.getItem(PREFERRED_VIDEO) || USER;
}

/**
 * Cycles through the available local video devices for the user's preferred video source
 */
export async function togglePreferredVideo(): Promise<string> {
  try {
    const currentValue = getPreferredVideo();
    const { video } = await getMediaDevices();

    if (video.length < 1) {
      return USER;
    }

    if (video.length === 1) {
      const nextValue = video[0].label;
      await setPreferredVideo(nextValue);
      return nextValue;
    }

    const currentIndex = video.findIndex(
      (device) => device.label === currentValue,
    );
    if (currentIndex === -1) {
      const nextValue = video[0].label;
      await setPreferredVideo(nextValue);
      return nextValue;
    }

    const nextIndex = currentIndex === video.length - 1 ? 0 : currentIndex + 1;
    const nextValue = video[nextIndex].label;
    await setPreferredVideo(nextValue);
    return nextValue;
  } catch (err) {
    errorLog(err);
    return USER;
  }
}

/* ~~~ Local Utils ~~~ */

function isFacingMode(name: string): name is VideoFacingMode {
  return [USER, ENVIRONMENT].includes(name);
}

async function setPreferredVideo(name: string) {
  window.localStorage.setItem(PREFERRED_VIDEO, name || USER);
}
