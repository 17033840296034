import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import AuthProvider, { useAuth } from '../../auth';
import history from '../../utils/history';
import RippleLoader from '../utils/ripple-loader';
import './app.scss';
import Header from './header';
import Main from './main';

function onRedirectCallback(appState: any) {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
}

export default function App() {
  return (
    <AuthProvider
      domain={process.env.REACT_APP_AUTH_DOMAIN || ''}
      client_id={process.env.REACT_APP_AUTH_CLIENT_ID || ''}
      redirect_uri={window.location.origin}
      audience={process.env.REACT_APP_AUTH_AUDIENCE || ''}
      onRedirectCallback={onRedirectCallback}>
      <ToastProvider autoDismissTimeout={2000} placement="top-center">
        <AppContent />
      </ToastProvider>
    </AuthProvider>
  );
}

function AppContent() {
  const { loading } = useAuth();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setReady(true);
      }, 500);
    }
  }, [loading]);

  if (loading || !ready) {
    return (
      <div className="app-loader">
        <RippleLoader color="secondary" />
      </div>
    );
  }

  return (
    <Router>
      <Header />
      <main className="app-content">
        <Main />
      </main>
    </Router>
  );
}
