import React, { MouseEvent } from 'react';
import './toggle-switch.scss';

export type ToggleSwitchProps = {
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
};

export default function ToggleSwitch({
  checked,
  disabled = false,
  onClick,
}: ToggleSwitchProps) {
  function handleClick(event: MouseEvent<HTMLInputElement>) {
    event.stopPropagation();
    onClick();
  }

  function handleChange() {}

  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onClick={handleClick}
        onChange={handleChange}
      />
      <span className="slider round"></span>
    </label>
  );
}
