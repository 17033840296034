import {
  AudioTrackPublication,
  createLocalTracks,
  LocalParticipant,
  LocalTrackPublication,
  Participant,
  VideoTrackPublication,
} from 'twilio-video';
import { AudioTrackType, TrackType, VideoTrackType } from '../types';
import { getMediaDevices, isAudioTrack, isVideoTrack } from '../utils';

export function getParticipantTracks(
  participant: LocalParticipant,
): TrackType[] {
  return Array.from(participant.tracks.values())
    .map(getTrack)
    .filter(isValidTrack);
}

export function unpublishMediaTracks(localParticipant: LocalParticipant) {
  const localTracks = getParticipantTracks(localParticipant);
  localParticipant.unpublishTracks(localTracks);
  for (const track of localTracks) {
    if (isVideoTrack(track) || isAudioTrack(track)) {
      track.stop();
    }
  }
}

export async function toggleVideoDevice(localParticipant: LocalParticipant) {
  const { video } = await getMediaDevices();

  if (video.length < 1) {
    throw new Error('no video devices found');
  } else if (video.length === 1) {
    return;
  }

  const currentVideoTracks = getParticipantVideoTracks(localParticipant);
  if (!Array.isArray(currentVideoTracks) || currentVideoTracks.length < 1) {
    throw new Error(
      'the local participant does not have an active video track',
    );
  } else if (currentVideoTracks.length > 1) {
    throw new Error(
      'the local participant has more than one active video track',
    );
  }

  const currentVideoTrack = currentVideoTracks[0];
  const currentDeviceLabel = currentVideoTrack.mediaStreamTrack.label;

  const currentIndex = video.findIndex(
    (device) => device.label === currentDeviceLabel,
  );

  if (currentIndex === -1) {
    throw new Error('could not find the active video device');
  }

  const nextIndex = currentIndex === video.length - 1 ? 0 : currentIndex + 1;

  const nextDeviceId = video[nextIndex].deviceId;

  unpublishMediaTracks(localParticipant);

  const newTracks = await createLocalTracks({
    audio: true,
    video: { deviceId: nextDeviceId },
  });

  return localParticipant.publishTracks(newTracks);
}

export function getParticipantAudioTracks(
  participant: Participant,
): AudioTrackType[] {
  const publications = Array.from(participant.audioTracks.values());
  const tracks = publications.map(getTrack);
  return tracks.filter(isAudioTrack);
}

export function getParticipantVideoTracks(
  participant: Participant,
): VideoTrackType[] {
  const publications = Array.from(participant.videoTracks.values());
  const tracks = publications.map(getTrack);
  return tracks.filter(isVideoTrack);
}

export function hasAudioTrack(trackPublication: LocalTrackPublication) {
  return (
    trackPublication.track !== null && trackPublication.track.kind === 'audio'
  );
}

export function hasVideoTrack(trackPublication: LocalTrackPublication) {
  return (
    trackPublication.track !== null && trackPublication.track.kind === 'video'
  );
}

function isValidTrack(track: TrackType | null): track is TrackType {
  return isAudioTrack(track) || isVideoTrack(track);
}

export function getTrack(
  trackPublication:
    | LocalTrackPublication
    | AudioTrackPublication
    | VideoTrackPublication,
) {
  return trackPublication.track as TrackType | null;
}
