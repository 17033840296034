import React from 'react';
import { Redirect } from 'react-router-dom';
import VideoChat from '../../components/video-chat';
import { clientIsCompatible } from '../../utils';

export default function Home() {
  if (!clientIsCompatible()) {
    return <Redirect to="/incompatible/33"></Redirect>;
  }

  return <VideoChat />;
}
