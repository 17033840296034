import debounce from 'lodash.debounce';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { AiFillCloseCircle } from 'react-icons/ai';
import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaVideo,
  FaVideoSlash,
} from 'react-icons/fa';
import { IoIosReverseCamera } from 'react-icons/io';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from '../../../auth';
import { debugLog, errorLog } from '../../../utils';
import Linkify from '../../utils/linkify';
import LoadingButton from '../../utils/loading-button';
import ToggleSwitch from '../../utils/toggle-switch';
import { AudioTrackType, VideoTrackType } from '../types';
import {
  getAudioEnabled,
  getDeviceErrorMessage,
  getMediaDevices,
  getVideoEnabled,
  initialMediaTracks,
  isAudioTrack,
  isVideoTrack,
  mediaEnabled,
  setAudioEnabled,
  setVideoEnabled,
  togglePreferredVideo,
} from '../utils';
import styles from './device-preview.module.scss';
import { eventLogger } from '../video-service';

export type DevicePreviewProps = {
  appointmentId: string;
  onCancel: () => void;
  onJoin: () => void;
};

export default function DevicePreview({
  appointmentId,
  onCancel,
  onJoin,
}: DevicePreviewProps) {
  const { getTokenSilently, user } = useAuth();
  const { addToast } = useToasts();
  const [useAudio, setUseAudio] = useState(getAudioEnabled());
  const [useVideo, setUseVideo] = useState(getVideoEnabled());
  const [videoTracks, setVideoTracks] = useState<VideoTrackType[]>([]);
  const [audioTracks, setAudioTracks] = useState<AudioTrackType[]>([]);
  const [audioDeviceCount, setAudioDeviceCount] = useState(0);
  const [videoDeviceCount, setVideoDeviceCount] = useState(0);

  const videoRef = useRef() as RefObject<HTMLVideoElement>;
  const audioRef = useRef() as RefObject<HTMLAudioElement>;

  function showErrorNotification(message: string, logText : string = "" ) {
    addToast(<Linkify text={message} />, {
      appearance: 'error',
      autoDismiss: false,
    });
    eventLogger({
      getAuthToken: getTokenSilently,
      user,
    }).logError(appointmentId, { error: `${message} - ${logText}` });
  }

  async function refreshMediaStream() {
    try {
      for (const track of [...audioTracks, ...videoTracks]) {
        if (track) {
          track.disable();
          track.stop();
        }
      }

      const tracks = await initialMediaTracks();
      for (const track of tracks) {
        if (isVideoTrack(track)) {
          if (!useVideo) {
            track.disable();
          }
          setVideoTracks((videoTracks) => [track, ...videoTracks]);
        } else if (isAudioTrack(track)) {
          if (!useAudio) {
            track.disable();
          }
          setAudioTracks((audioTracks) => [track, ...audioTracks]);
        }
      }
    } catch (err) {
      debugLog('failed to create local media stream');
      errorLog(err);
      showErrorNotification(getDeviceErrorMessage(err, appointmentId) , err );
    }
  }

  useEffect(() => {
    const updateMediaDevices = debounce(async function updateMediaDevices() {
      const { audio, video } = await getMediaDevices();
      setAudioDeviceCount(audio.length);
      setVideoDeviceCount(video.length);
      refreshMediaStream();

      debugLog(
        `${audio.length} audio devices`,
        audio.map((device) => device.label),
      );

      debugLog(
        `${video.length} video devices`,
        video.map((device) => device.label),
      );
    }, 1000);

    if (mediaEnabled()) {
      navigator.mediaDevices.addEventListener(
        'devicechange',
        updateMediaDevices,
      );
      updateMediaDevices();

      return () =>
        navigator.mediaDevices.removeEventListener(
          'devicechange',
          updateMediaDevices,
        );
    } else {
      debugLog('client does not have media devices enabled');
    }
  }, []);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack && audioRef.current) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
        for (const track of audioTracks) {
          track.disable();
          track.stop();
        }
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack && videoRef.current) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
        for (const track of videoTracks) {
          track.disable();
          track.stop();
        }
      };
    }
  }, [videoTracks]);

  function toggleAudio() {
    if (audioDeviceCount < 1) {
      return;
    }

    const enabled = !useAudio;
    setAudioEnabled(enabled);
    setUseAudio(enabled);

    for (const track of audioTracks) {
      if (track) {
        if (enabled) {
          track.enable();
        } else {
          track.disable();
        }
      }
    }
  }

  function toggleVideo() {
    if (videoDeviceCount < 1) {
      return;
    }

    const enabled = !useVideo;
    setVideoEnabled(enabled);
    setUseVideo(enabled);

    for (const track of videoTracks) {
      if (track) {
        if (enabled) {
          track.enable();
        } else {
          track.disable();
        }
      }
    }
  }

  async function nextVideoDevice() {
    try {
      if (videoDeviceCount < 1) {
        return;
      }
      await togglePreferredVideo();
      await refreshMediaStream();
    } catch (err) {
      debugLog('failed to toggle the active video device');
      errorLog(err);
    }
  }

  function handleCancel() {
    onCancel();
  }

  function handleJoin() {
    onJoin();
  }

  const AudioIcon = useAudio ? FaMicrophone : FaMicrophoneSlash;
  const VideoIcon = useVideo ? FaVideo : FaVideoSlash;
  const noAudioDevices = audioDeviceCount < 1;
  const noVideoDevices = videoDeviceCount < 1;

  return (
    <div className={styles.devicePreview}>
      <LoadingButton
        className={styles.cancelButton}
        tooltip="Exit Appointment"
        tooltipPlacement="bottom"
        onClick={handleCancel}
        loading={false}>
        <AiFillCloseCircle fontSize="2em" />
      </LoadingButton>

      <div className={styles.videoContainer}>
        <div className={styles.videoToolbarGradient} />
        <audio ref={audioRef} autoPlay={true} muted={true} />
        <video className={styles.video} ref={videoRef} autoPlay={true} />

        <div className={styles.toolbar}>
          <div className={styles.controls}>
            <div className={styles.audioControl}>
              <AudioIcon
                className={styles.audioIcon}
                color={noAudioDevices ? '#ffffff3d' : 'white'}
                onClick={toggleAudio}
              />
              <ToggleSwitch
                disabled={noAudioDevices}
                checked={noAudioDevices === true ? false : useAudio}
                onClick={toggleAudio}
              />
            </div>
            <div className={styles.videoControl}>
              <VideoIcon
                className={styles.videoIcon}
                color={noVideoDevices ? '#ffffff3d' : 'white'}
                onClick={toggleVideo}
              />
              <ToggleSwitch
                disabled={noVideoDevices}
                checked={noVideoDevices === true ? false : useVideo}
                onClick={toggleVideo}
              />
            </div>
          </div>
          {videoDeviceCount > 1 && (
            <div>
              <LoadingButton
                className={styles.changeVideoDevice}
                tooltip="Change Video"
                tooltipPlacement="bottom"
                onClick={nextVideoDevice}
                loading={false}>
                <IoIosReverseCamera className={styles.changeVideoDeviceIcon} />
              </LoadingButton>
            </div>
          )}
        </div>
      </div>

      <div className={styles.appoinmentActions}>
        <Button
          disabled={noVideoDevices || noAudioDevices}
          className={styles.joinButton}
          variant="secondary"
          onClick={handleJoin}>
          Join
        </Button>
      </div>
    </div>
  );
}
