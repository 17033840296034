import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import VideoChatRoom from '../../components/video-chat/video-chat-room';
import { clientIsCompatible } from '../../utils';

export default function Room() {
  const { appointmentCode } = useParams();
  if (!clientIsCompatible()) {
    return <Redirect to="/incompatible/33"></Redirect>;
  }

  return <VideoChatRoom appointmentCode={appointmentCode || ''} />;
}
