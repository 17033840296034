import { detect } from 'detect-browser';
import { isSupported } from 'twilio-video';

export function clientIsCompatible(): boolean {
  return true || isSupported;
}

export function getCompatErrMsg(errorCode: number): string {
  if (errorCode === 33) {
    const browser = detect();
    if (!browser) {
      return 'your device is not supported by this application';
    }
    return `${browser.name} version ${browser.version} is not supported by this application`;
  }
  return `encountered an unexpected error code: ${errorCode}`;
}
