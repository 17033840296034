import debounce from 'lodash.debounce';
import React, { MouseEvent, useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {
  AiFillCloseCircle,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from 'react-icons/ai';
import { useToasts } from 'react-toast-notifications';
import Video, {
  ConnectOptions,
  RemoteParticipant as RemoteParticipantType,
  Room as RoomType,
} from 'twilio-video';
import { useAuth } from '../../../auth';
import { debugLog, errorLog, isPractitioner } from '../../../utils';
import Linkify from '../../utils/linkify';
import LoadingButton from '../../utils/loading-button';
import LocalParticipant from '../local-participant';
import { unpublishMediaTracks } from '../local-participant/utils';
import RemoteParticipant from '../remote-participant';
import { NetworkQualityVerbosity } from '../types';
import {
  getDeviceErrorMessage,
  initialMediaTracks,
  mediaEnabled,
} from '../utils';
import { eventLogger } from '../video-service';
import WaitingScreen from '../waiting-screen';
import './room.scss';

let unloadHandler = () => {};

window.addEventListener('beforeunload', () => {
  debugLog('application page unloading');
  unloadHandler();
});

export type RoomProps = {
  appointmentCode: string;
  token: string;
  handleLogout: () => void;
};

export default function Room({
  appointmentCode,
  token,
  handleLogout,
}: RoomProps) {
  const {
    isAuthenticated,
    getIdTokenClaims,
    getTokenSilently,
    user,
  } = useAuth();
  const { addToast } = useToasts();
  const [leavingRoom, setLeavingRoom] = useState(false);
  const [zoom, setZoom] = useState(false);
  const [localAudioAttached, setLocalAudioAttached] = useState(false);
  const [localVideoAttached, setLocalVideoAttached] = useState(false);
  const [room, setRoom] = useState<RoomType | null>(null);

  const [remoteParticipants, setRemoteParticipants] = useState<
    RemoteParticipantType[]
  >([]);

  const onZoom = debounce(function onZoom(event?: MouseEvent<HTMLElement>) {
    if (event) {
      event.preventDefault();
    }
    setZoom(!zoom);
  });

  const onExitRoom = debounce(async function onExitRoom(
    event?: MouseEvent<HTMLElement>,
  ) {
    try {
      if (event) {
        event.preventDefault();
      }

      if (!room) {
        debugLog('leaving room');
        return handleLogout();
      }

      debugLog('disconnecting from room');
      unpublishMediaTracks(room.localParticipant);
      room.disconnect();
      setRoom(null);
      debugLog('leaving room');
      handleLogout();
    } catch (err) {
      debugLog('failed to exit room');
      errorLog(err);
    }
  },
  200);

  useEffect(() => {
    if (leavingRoom) {
      onExitRoom();
    }
  }, [leavingRoom]);

  useEffect(() => {
    const refreshLocalTracks = debounce(async function refreshLocalTracks() {
      if (!room) {
        return;
      }

      try {
        unpublishMediaTracks(room.localParticipant);
        debugLog('unpublished old local media tracks');
        const newTracks = await initialMediaTracks();
        room.localParticipant.publishTracks(newTracks);
        debugLog('published new local media tracks');
      } catch (err) {
        debugLog('failed to refresh local tracks');
        errorLog(err);
      }
    }, 1000);

    if (mediaEnabled()) {
      navigator.mediaDevices.addEventListener(
        'devicechange',
        refreshLocalTracks,
      );

      return () =>
        navigator.mediaDevices.removeEventListener(
          'devicechange',
          refreshLocalTracks,
        );
    } else {
      debugLog('client does not have media devices enabled');
    }
  }, [room]);

  useEffect(() => {
    setLeavingRoom(false);

    function participantConnected(participant: RemoteParticipantType) {
      debugLog('remote participant connected');
      setRemoteParticipants((prevParticipants) => [
        ...prevParticipants,
        participant,
      ]);
    }

    function participantDisconnected(participant: RemoteParticipantType) {
      debugLog('remote participant disconnected');

      if (!isAuthenticated) {
        setLeavingRoom(true);
      }

      getIdTokenClaims()?.then((claims) => {
        if (!claims || !isPractitioner(claims)) {
          setLeavingRoom(true);
        }
      });

      setRemoteParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant),
      );
    }

    function handleReconnecting() {
      debugLog('reconnecting to video room');
    }

    function handleReconnected() {
      debugLog('reconnected to video room');
    }

    initialMediaTracks()
      .then((tracks) => {
        const connectOpts: ConnectOptions = {
          tracks,
          networkQuality: {
            local: NetworkQualityVerbosity.DETAILED,
            remote: NetworkQualityVerbosity.DETAILED,
          },
        };

        Video.connect(token, connectOpts)
          .then((room) => {
            debugLog('joined video chat room');
            unloadHandler = () => room.disconnect();
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.on('reconnecting', handleReconnecting);
            room.on('reconnected', handleReconnected);
            room.participants.forEach(participantConnected);
          })
          .catch((err) => {
            debugLog('failed to join the video chat room');
            errorLog(err);
            setLeavingRoom(true);
          });
      })
      .catch((err) => {
        debugLog('failed to join the video chat room');
        errorLog(err);
        showErrorNotification(getDeviceErrorMessage(err, appointmentCode) , err );
      });

    return () => {
      onExitRoom();
    };
  }, [token]);

  function showErrorNotification(message: string , logText : string = "" ) {
    addToast(<Linkify text={message} />, {
      appearance: 'error',
      autoDismiss: false,
    });
    eventLogger({
      getAuthToken: getTokenSilently,
      user,
    }).logError(appointmentCode, { error: `${message} - ${logText}` });
    setLeavingRoom(true);
  }

  return (
    <>
      <div className="room">
        {localAudioAttached && localVideoAttached && (
          <>
            <LoadingButton
              className="exit-appointment-button"
              tooltip="Exit Appointment"
              tooltipPlacement="bottom"
              onClick={onExitRoom}
              loading={false}>
              <AiFillCloseCircle fontSize="2em" />
            </LoadingButton>
            {remoteParticipants.length > 0 && (
              <LoadingButton
                className="zoom-button"
                tooltip={zoom ? 'Zoom Out' : 'Zoom In'}
                tooltipPlacement="bottom"
                onClick={onZoom}
                loading={false}>
                {zoom && <AiOutlineZoomOut fontSize="2em" />}
                {!zoom && <AiOutlineZoomIn fontSize="2em" />}
              </LoadingButton>
            )}
          </>
        )}
        <div className="local-participant">
          {room ? (
            <LocalParticipant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
              onAudioAttached={() => setLocalAudioAttached(true)}
              onVideoAttached={() => setLocalVideoAttached(true)}
            />
          ) : (
            <div className="local-participant-loader">
              <Spinner
                className="local-participant-loader-spinner"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </div>
          )}
        </div>

        {remoteParticipants.length > 0 ? (
          <div
            className={['remote-participant', zoom ? 'zoom' : 'zoomout'].join(
              ' ',
            )}>
            {remoteParticipants.map((participant) => (
              <RemoteParticipant
                key={participant.sid}
                participant={participant}
              />
            ))}
          </div>
        ) : (
          <WaitingScreen
            appointmentCode={appointmentCode}
            message="Waiting for Patient to Join with this Appointment ID:"
          />
        )}
      </div>
    </>
  );
}
