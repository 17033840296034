import React from 'react';
import { useParams } from 'react-router-dom';
import { getCompatErrMsg } from '../../utils';
import styles from './incompatible.module.scss';
import { GiEgyptianWalk } from 'react-icons/gi';

export default function Incompatible() {
  const { errorCode } = useParams();
  return (
    <div>
      <GiEgyptianWalk className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.prefix}>unsupported client:</div>
        <div className={styles.message}>
          {getCompatErrMsg(Number(errorCode))}
        </div>
      </div>
    </div>
  );
}
