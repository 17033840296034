import { User } from '../../types';
import { getApiUrl, errorLog } from '../../utils';
import { GetVideoChatTokenDTO, VideoChatTokenDTO } from './types';

export type ErrorEventLogDTO = {
  error: string;
};

export type VideoAuthOptions = {
  getAuthToken: () => Promise<string> | undefined;
  user?: User;
};

export type EventLogger = ReturnType<typeof eventLogger>;

export function eventLogger({ getAuthToken, user }: VideoAuthOptions) {
  const logJoin = log('event');
  const logError = log<ErrorEventLogDTO>('error');

  return {
    logJoin,
    logError,
  };

  function log<P = undefined>(uri: string) {
    return async (appointmentId: string, payload?: P) => {
      if (!appointmentId) {
        errorLog('tried to log an event without supplying an appointment ID');
        return false;
      }

      if (typeof appointmentId !== 'string' || appointmentId.length < 1) {
        errorLog('tried to log an event with an invalid appointment ID');
        return false;
      }

      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
      };

      if (user) {
        headers['X-Username'] = user.email;
        headers['X-User-Id'] = user.sub;
      }

      try {
        const authToken = await getAuthToken();
        if (authToken) {
          headers.Authorization = `Bearer ${authToken}`;
        }
      } catch {}

      try {
        const response = await fetch(`${getApiUrl()}/log/${uri}`, {
          method: 'POST',
          mode: 'cors',
          headers,
          body: JSON.stringify({
            ...payload,
            appointment_code: appointmentId,
          }),
        });
        return response.ok;
      } catch (error) {
        errorLog(error);
        return false;
      }
    };
  }
}

export async function getVideoChatToken(
  data: GetVideoChatTokenDTO,
  { getAuthToken, user }: VideoAuthOptions,
): Promise<VideoChatTokenDTO> {
  if (!data) {
    throw new Error(
      'tried to request a video chat token without supplying any data',
    );
  }

  const { appointment_code } = data;
  if (typeof appointment_code !== 'string' || appointment_code.length < 1) {
    throw new Error(
      'tried to request a video chat token without supplying an appointment ID',
    );
  }

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  if (user) {
    headers['X-Username'] = user.email;
    headers['X-User-Id'] = user.sub;
  }

  try {
    const authToken = await getAuthToken();
    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }
  } catch {}

  try {
    const response = await fetch(`${getApiUrl()}/video/token`, {
      method: 'POST',
      mode: 'cors',
      headers,
      body: JSON.stringify({
        appointment_code,
      }),
    });

    const token = await response.json();
    return token;
  } catch (error) {
    errorLog(error);
    return {
      token: null,
      error: error.message,
    };
  }
}
