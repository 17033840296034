import React from 'react';
import styles from './linkify.module.scss';

// const URL_REGEX = /(https?:\/\/[^\s$.?#].[A-Za-z0-9-\._~]*)/g;
const URL_REGEX = /(https?:\/\/[^\s$.?#].[^\s,]*)/g;

export type LinkifyProps = {
  text: string;
};

export default function Linkify({ text }: LinkifyProps) {
  const urls = text.match(URL_REGEX);
  if (!urls || urls.length < 1) {
    return <div className={styles.text}></div>;
  }

  const chunks = text.split(URL_REGEX).filter((chunk) => !urls.includes(chunk));
  return (
    <div className={styles.text}>
      {chunks.map((chunk, i) =>
        urls[i] ? (
          <span>
            {chunk}
            <a href={urls[i]}>{urls[i]}</a>
          </span>
        ) : (
          <span>{chunk}</span>
        ),
      )}
    </div>
  );
}
