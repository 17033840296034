import { detect } from 'detect-browser';

export function getDeviceErrorMessage(err: Error, appointmentId?: string) {
  let notFoundString = 'requested device not found';
  const browser = detect();

  if (browser) {
    if (browser.name === 'firefox') {
      notFoundString = 'the object can not be found here.';
    }
  }

  switch (err.message.toLowerCase()) {
    case notFoundString:
      return appendRedirect({
        message:
          'Please connect a video device. If you already have one connected you may need to enable video permissions for this application',
        appointmentId,
      });
    case 'permission denied':
      return appendRedirect({
        message:
          'Your browser is blocking the camera or the microphone, please enable this app access to them and try again',
        appointmentId,
      });
    case 'could not start video source':
      return appendRedirect({
        message: `Your browser can't get access to your video device, please make sure it's not being used by a different application`,
        appointmentId,
      });
    case 'could not start audio source':
      return appendRedirect({
        message: `Your browser can't get access to your audio device, please make sure it's not being used by a different application`,
        appointmentId,
      });
    default:
      return unexpectedError(appointmentId);
  }
}

export function unexpectedError(appointmentId?: string) {
  return appendRedirect({
    message: 'Encountered an unexpected error',
    defaultAppend: 'Please try again',
    appointmentId,
  });
}

type AppendRedirect = {
  message: string;
  appointmentId?: string;
  defaultAppend?: string;
};

function appendRedirect({
  message,
  appointmentId,
  defaultAppend,
}: AppendRedirect) {
  let useURL = false;
  const v1URL = process.env.REACT_APP_V1_URL || '';

  try {
    if (v1URL) {
      const v1Host = new URL(v1URL).hostname.toLowerCase().trim();
      const currentHost = window.location.hostname.toLowerCase().trim();
      if (v1Host !== currentHost) {
        useURL = true;
      }
    }
  } catch (err) {}

  return `${message}. ${
    useURL
      ? `If you continue to experience this issue please try visiting: ${
          appointmentId ? `${v1URL}/room/${appointmentId}` : v1URL
        }`
      : `${defaultAppend ? `${defaultAppend}.` : ''}`
  }`.trim();
}

let x = 'Please try again';
